<template>
  <div class="page-authorizers">
    <h2 class="p-title">公众号列表</h2>
    <yos-tab-nav :list="tabNav" />
    <div class="p-card fix">
      <yos-button class="yos-button-primary" @click="setAuthorizationNeedParams">新增公众号</yos-button>
      <!-- <yos-button class="yos-button-primary export">导出数据</yos-button> -->
      <form class="p-search-bar" @submit.prevent="onSubmit">
        <span>公众号：</span>
        <yos-input
          v-model.trim="keyword"
          placeholder="搜索公众号名称"
        />
        &emsp;
        <span>授权状态：</span>
        <select v-model="status" class="yos-select">
          <option :value="-1">不限</option>
          <option :value="1">正常</option>
          <option :value="0">失效</option>
        </select>
        <yos-button type="submit" class="yos-button-primary">搜索</yos-button>
        <yos-button type="reset" @click="onReset">重置</yos-button>
      </form>
      <table
        cellspacing="0"
        class="m-table"
      >
        <thead>
          <tr>
            <!-- <th><i class="icon-checkbox" />全选</th> -->
            <th>公众号</th>
            <th>授权状态</th>
            <th>总用户数</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in authorizers" :key="item.authorizerAppid">
            <!-- <td><i class="icon-checkbox" /></td> -->
            <td>{{ item.nickName }}</td>
            <td>{{ item.status === 1 ? '正常' : '失效' }}</td>
            <td>{{ item.totalFollowed }}</td>
            <td>
              <router-link class="option" :to="`/setting/authorizers/${item.authorizerAppid}`">编辑</router-link>
              <span class="option" @click="onDelete(item.authorizerAppid, item.nickName)">删除</span>
            </td>
          </tr>
        </tbody>
      </table>
      <yos-pager
        :baseUrl="baseUrl"
        :totalPage="totalPage"
        @change="onPageChange"
      />
    </div>
  </div>
</template>

<script>
import YosTabNav from '../../components/other/tab-nav'
import YosPager from '../../components/other/yos-pager'
import { reactive, ref, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { addClass, gotToWeixin, serialize } from '../../util'
import { useDialog, usePage, useServer, useTabNav, useToast } from '../../hook'
import {
  upateAuthorizerStatus,
  getAuthorizers,
  getAuthorizationNeed
} from '../../data'

export default {
  components: {
    YosTabNav,
    YosPager
  },
  setup() {
    const toast = useToast()
    const dialog = useDialog()
    const route = useRoute()
    const router = useRouter()
    const totalPage = ref(-1)
    const authorizers = ref([])
    const nav = [{
      name: '公众号列表',
      to: '/setting/authorizers'
    }]
    const state = reactive({
      keyword: route.query.keyword || '',
      status: -1
    })
    const url = '/setting/authorizers'

    const { page, baseUrl } = usePage(url, {keyword: state.keyword})

    const setAuthorizersParams = useServer(getAuthorizers, (data) => {
      authorizers.value = data.authorizers
      totalPage.value = data.totalPage
    })

    const setUpdateAccountStatusParams = useServer(upateAuthorizerStatus, ({ msg }) => {
      toast(msg)
      setAuthorizersParams({keyword: state.keyword, status: state.status, page})
    })

    const setAuthorizationNeedParams = useServer(getAuthorizationNeed, gotToWeixin)

    const onDelete = (id, name) => {
      dialog({
        title: '删除公众号',
        content: `确定删除公众号“${name}”？`,
        okText: '删除',
        onOk: () => {
          setUpdateAccountStatusParams({ id })
        }
      })
    }

    const onSubmit = () => {
      const params = {keyword: state.keyword, status: state.status, page: 1}
      router.replace(serialize(url, params))
      setAuthorizersParams(params)
    }

    const onReset = () => {
      state.keyword = ''
      onSubmit()
    }

    const onPageChange = (page) => {
      setAuthorizersParams({keyword: state.keyword, status: state.status, page})
    }

    setAuthorizersParams({keyword: state.keyword, status: state.status, page})

    return {
      tabNav: useTabNav(nav),
      ...toRefs(state),
      page,
      baseUrl,
      totalPage,
      authorizers,
      addClass,
      setAuthorizationNeedParams,
      onDelete,
      onSubmit,
      onReset,
      onPageChange
    }
  }
}
</script>

<style lang="postcss">
.page-authorizers {
  .export {
    margin-left: 10px;
  }
}
</style>
